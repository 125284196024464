let youtubeVideosQueue = [];
let activePlayers = [];

export function VideoPlayers() {
    document.addEventListener('DOMContentLoaded', () => {
        enhanceYouTubeEmbeds();
        initializeVideoPlayers();
    });
}

function initializeVideoPlayers() {
    const videoContainers = document.querySelectorAll('.js-video');

    if (videoContainers.length === 0) return;

    videoContainers.forEach(container => {
        const videoThumbnail = container.querySelector('.js-video-thumbnail');
        const videoPlayer = container.querySelector('.js-video-player');

        if (!videoThumbnail || !videoPlayer) return;

        videoThumbnail.addEventListener('click', () => playVideo(videoThumbnail, videoPlayer));
        videoThumbnail.addEventListener('keypress', (e) => {
            if (e.key === 'Enter' || e.key === ' ') {
                playVideo(videoThumbnail, videoPlayer);
            }
        });
    });

    if (document.querySelector('.js-video-player[src*="youtube.com"]')) {
        loadYouTubeAPI();
    }
}

function playVideo(thumbnail, player) {
    thumbnail.classList.add('is-loading');
    pauseAllVideos();
    if (player.src.includes('youtube.com')) {
        playYouTubeVideo(thumbnail, player);
    } else {
        playHTMLVideo(thumbnail, player);
    }
}

function pauseAllVideos() {
    activePlayers.forEach(player => {
        if (player.getPlayerState && player.getPlayerState() === YT.PlayerState.PLAYING) {
            player.pauseVideo();
        } else if (player.pause && typeof player.pause === 'function') {
            player.pause();
        }
    });
}

function playYouTubeVideo(thumbnail, player) {
    const videoId = extractYouTubeId(player.src);
    if (!videoId) {
        console.error('Unable to extract YouTube video ID');
        return;
    }

    const placeholderDiv = document.createElement('div');
    placeholderDiv.id = 'youtube-player-' + videoId;
    player.parentNode.replaceChild(placeholderDiv, player);

    youtubeVideosQueue.push({ id: placeholderDiv.id, videoId: videoId });

    if (window.YT && window.YT.Player) {
        createYouTubePlayer(thumbnail, placeholderDiv.id, videoId);
    }
}

function loadYouTubeAPI() {
    if (!document.querySelector('script[src="https://www.youtube.com/iframe_api"]')) {
        const tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }

    window.onYouTubeIframeAPIReady = function() {
        youtubeVideosQueue.forEach(video => {
            createYouTubePlayer(null, video.id, video.videoId);
        });
    };
}

function createYouTubePlayer(thumbnail, playerId, videoId) {
    const player = new YT.Player(playerId, {
        height: 'auto',
        width: '100%',
        videoId: videoId,
        events: {
          'onReady': (event) => {
            activePlayers.push(event.target);
            event.target.playVideo();
          },
          'onStateChange': (event) => {
            if (event.data == YT.PlayerState.PLAYING) {
              const element = document.getElementById(playerId);
              element.classList.add('!opacity-100');

              if (thumbnail) {
                thumbnail.style.display = 'none';
              }
            }
          }
        }
    });
}

function playHTMLVideo(thumbnail, player) {
    if (typeof player.play === 'function') {
        activePlayers.push(player);
        player.play().catch(error => {
            console.warn('Error playing video:', error);
        });

        player.addEventListener('playing', () => {
            if (thumbnail) {
                thumbnail.style.display = 'none';
            }
        });
    } else {
        console.warn('Video player does not support HTML5 video API.');
    }
}

function extractYouTubeId(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : null;
}

function enhanceYouTubeEmbeds() {
    // Find all elements with js-richtext-video class
    const videoContainers = document.querySelectorAll('.richtext figure iframe');

    videoContainers.forEach(container => {
        const figure = container.closest('figure');
        if (figure) {
            const iframe = figure.querySelector('iframe');
            if (iframe && iframe.src && iframe.src.includes('youtube.com/embed/')) {
                // Add js-video-player class to the iframe
                iframe.classList.add('js-video-player');

                // Extract video ID
                const videoId = iframe.src.split('/').pop().split('?')[0];

                // Create thumbnail element
                const thumbnailElement = document.createElement('div');
                thumbnailElement.className = 'video-thumbnail js-video-thumbnail';
                thumbnailElement.tabIndex = 0;
                thumbnailElement.setAttribute('role', 'button');
                thumbnailElement.setAttribute('aria-label', 'Play video');
                thumbnailElement.innerHTML = `
                    <img src="https://i.ytimg.com/vi/${videoId}/maxresdefault.jpg" alt="Video thumbnail">
                    <div class="video-play">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 70 71" fill="none">
                        <circle cx="35" cy="35.4839" r="35" fill="#F18A0F"></circle>
                        <path d="M48.6432 34.6359C49.2699 35.0276 49.2699 35.9402 48.6432 36.3319L27.53 49.5276C26.8639 49.9439 26 49.4651 26 48.6796L26 22.2881C26 21.5027 26.864 21.0239 27.53 21.4401L48.6432 34.6359Z" fill="white"></path>
                    </svg>
                    </div>
                `;

                // Create a new div to wrap everything
                const wrapperDiv = document.createElement('div');
                wrapperDiv.className = 'video js-video [&>.video-wrapper>iframe]:opacity-0';

                // Create video-wrapper div
                const videoWrapper = document.createElement('div');
                videoWrapper.className = 'video-wrapper';

                // Move the iframe into the video-wrapper
                videoWrapper.appendChild(iframe);

                // Add the thumbnail to the video-wrapper
                videoWrapper.appendChild(thumbnailElement);

                // Add the video-wrapper to the main wrapper
                wrapperDiv.appendChild(videoWrapper);

                // Replace the original figure with the new structure
                figure.parentNode.replaceChild(wrapperDiv, figure);
            }
        }
    });
}
