<!--

This Vue component renders a taxonomy layout with collapsible sections using a grid layout
to display the various sections and children. The component includes a button that allows the
user to expand or collapse all sections simultaneously.

It imports the `TaxonomyLevel` component for rendering each level of the taxonomy. The number
of levels is virtually unlimited, as each level can contain nested children with the same structure.

Each level/item can have the following properties:
- `kicker`: A short text displayed above the title. (if not an accordion)
- `title`: The main title of the item. (if not an accordion)
- `style`: A string representing CSS classes for styling. Can be the colors 'purple-1', 'blue-8',
'orange-7', 'yellow-4', 'orange-2', 'blue-4', 'green-3', 'blue-8', or 'ghost', which works for the
smaller white background accordion sections.
- `colSpan`: Specifies the number of columns the item should span in the grid.
- `accordion`: An object containing nested children with properties:
    - `title`: The title of the accordion.
    - `copy`: HTML string for the content of the accordion.
    - `taxonomyCta`: An Hyper field.
    - `skeLevelCta`: An Hyper field.
    - `children`: An array of nested children with similar structure.
    - `linkList`: An array of links for additional resources.

The component's props include:
- `activetaxonomy`: An array representing the active taxonomies.
- `hideable`: Indicates whether the taxonomy main component can be hidden or shown.

The component uses the following functions:
- `hideShow`: Toggles the visibility of the taxonomy layout.
- `expand`: Toggles the `open` attribute of all `details` elements on the page to either expand
  or collapse them all.
- `changed`: Sets the `levelChanged` value to true when a level is changed from the `TaxonomyLevel`
  component.

The component also uses the following refs:
- `visible`: Tracks the visibility of the taxonomy layout.
- `expanded`: Tracks whether the taxonomy sections are expanded.
- `levelChanged`: Tracks whether a level has been changed.

-->


<template>
    <div class="grid gap-4">
        <nav class="max-md:flex-wrap flex gap-3 no-print" :class="{
            'justify-start': !visible && activetaxonomy.length > 0,
            'justify-end': (activetaxonomy && visible) || activetaxonomy <= 0
        }">
            <div class="max-md:text-center text-lg flex-grow" v-if="activetaxonomy && visible">Where am I in the Reading Universe Taxonomy?</div>

            <div class="max-md:w-full max-md:flex-wrap max-md:justify-center flex gap-3">
                <button class="button button--xxsmall" v-if="visible || !hideable" @click="expand">
                    <span class="block w-[12px] transform" :class="expanded ? 'rotate-45' : ''">
                        <div v-html="PlusThick" class="w-full h-auto"></div>
                    </span>
                    <template v-if="expanded">
                        Collapse
                    </template>
                    <template v-else>
                        Expand
                    </template>
                    Full Taxonomy
                </button>
                <button class="button button--xxsmall" v-if="hideable" @click="hideShow">
                    <template v-if="visible">Hide</template>
                    <template v-else>Show</template>
                    RU Taxonomy
                    <span class="block w-[12px] transform" :class="visible ? 'rotate-45' : ''">
                        <div v-html="PlusThick" class="w-full h-auto"></div>
                    </span>
                </button>
            </div>
        </nav>

        <article class="taxonomy lg:grid-cols-4 items-start" :class="{
            'is-muted': activetaxonomy && activetaxonomy.length > 0,
            'is-expanded': expanded,
            'was-changed': levelChanged,
        }" v-if="visible || !hideable">
            <TaxonomyLevel v-for="item in data" :key="item.id" :item="item" :level="1" :activetaxonomy="activetaxonomy" @changed="changed" :levelChanged="levelChanged" />
        </article>
    </div>
</template>

<script setup>
    import { ref, onMounted, nextTick } from 'vue';
    import PlusThick from '/src/svgs/PlusThick.svg?raw';
    import TaxonomyLevel from './TaxonomyLevel.vue';

    const props = defineProps({
        activetaxonomy: {
            type: Array,
            default: []
        },
        hideable: {
            type: Boolean,
            default: false
        },
        where: {
            type: String,
            default: null
        },
        header: {
            type: Object,
            default: null
        },
        taxonomy: {
            type: Array,
            default: null
        },
        startvisible: {
            type: Boolean,
            default: false
        }
    });
    const updateSameRowAsActive = () => {
        const taxonomy = document.querySelector('.taxonomy');
        if (!taxonomy) return;

        const firstLevelSections = taxonomy.querySelectorAll(':scope > .taxonomy-section');
        let currentRowWidth = 0;
        let currentRowSections = [];
        let hasActiveInCurrentRow = false;

        firstLevelSections.forEach((section, index) => {
            // Remove the class if it was previously added
            section.classList.remove('--same-row-as-an-active');

            // Determine the column span of the current section
            const colSpanMatch = section.className.match(/taxonomy-section--(\d)-cols/);
            const sectionWidth = colSpanMatch ? parseInt(colSpanMatch[1]) : 4; // Default to 4 if not specified

            // Check if this section starts a new row
            if (currentRowWidth + sectionWidth > 4) {
                processRow(currentRowSections, hasActiveInCurrentRow);
                // Reset for the new row
                currentRowWidth = 0;
                currentRowSections = [];
                hasActiveInCurrentRow = false;
            }

            // Add the current section to the row
            currentRowWidth += sectionWidth;
            currentRowSections.push(section);

            // Check if this section is active
            if (section.classList.contains('is-active')) {
                hasActiveInCurrentRow = true;
            }

            // If we've reached the end of a row or it's the last section, process the row
            if (currentRowWidth === 4 || index === firstLevelSections.length - 1) {
                processRow(currentRowSections, hasActiveInCurrentRow);
            }
        });
    }

    const processRow = (rowSections, hasActive) => {
        if (hasActive) {
            rowSections.forEach(section => {
                section.classList.add('--same-row-as-an-active');

                // Find and open the <details> element inside this section
                const details = section.querySelector('details');
                if (details) {
                    details.open = true;
                }
            });
        }
    }

    const data = [
        {
            kicker: props.header.kicker,
            title: props.header.title,
            style: props.header.style
        },
        ...props.taxonomy
    ];

    const visible = ref(props.startvisible);
    const expanded = ref(false);
    const levelChanged = ref(false);

    const hideShow = () => {
        visible.value = props.hideable ? !visible.value : true;

        /* Wait for the DOM to update before calling the function */
        nextTick(() => {
            updateSameRowAsActive();
        });
    };

    const expand = () => {
        const details = document.querySelectorAll('details');
        details.forEach((detail) => {
            detail.open = !expanded.value;
        });

        expanded.value = !expanded.value;

        if (expanded.value) {
            levelChanged.value = true;
        } else {
            levelChanged.value = false;

            /* Open <details> directly under the main (last) active taxonomy */
            if (props.activetaxonomy.length > 0) {
                //console.log('props.activetaxonomy', props.activetaxonomy);
                const activeTaxonomy = props.activetaxonomy[props.activetaxonomy.length - 1];
                const activeDetails = document.querySelectorAll(`details[data-taxonomy="${activeTaxonomy}"]`);
                activeDetails.forEach((detail) => {
                    detail.open = true;
                });
            }
        }
    };

    /* This function is called when a level is changed from the TaxonomyLevel component and sets the levelChanged value to true */
    const changed = (newValue) => {
        levelChanged.value = newValue;
    };

    onMounted(() => {
        updateSameRowAsActive();
    });

</script>

<style scoped>

</style>
