import A11yDialog from 'a11y-dialog';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { Formie } from '../../../vendor/verbb/formie/src/web/assets/frontend/src/js/formie-lib';


const newsletterPopup = {
    setCookie(name,value,days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "")  + expires + "; path=/";
    },
    getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for(var i=0;i < ca.length;i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1,c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    },
    eraseCookie(name) {   
        document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    },
    dialog: {},
    form: {},
    init() {
        // Check to see if the User, if present, is already subscribed
        // If so, don't show the popup, which here just means "stop"
        // Otherwise, they're not subscribed, so check the rest of the conditions
        const target = document.querySelector('#user-id-container');
        const subscribed = target ? target.dataset.subscribed == 'subscribed' : false;

        // Get the popup element, for later use
        const popup = document.querySelector('#newsletter-popup');

        // Get the contents of the popup, for later use
        const closeButton = popup.querySelector('#popup-close');
        const newsletterForm = popup.querySelector('form');
        const formButton = newsletterForm ? newsletterForm.querySelector('button[type="submit"]') : null;
        const self = this;

        if (subscribed) {
            this.setCookie('subscribed', true, 1);
        } else {
            // Check for 'isRepeatVisitor' cookie
            const isRepeatVisitor = this.getCookie('isRepeatVisitor' );

            // If it is present, it's not the first page load, so we can show the popup now
            if (isRepeatVisitor && isRepeatVisitor.length > 0) {
                // But first, check to see if they've dismissed the popup (which also happens on form submission)
                const hasDismissedPopup = this.getCookie('hasDismissedPopup');

                // If they haven dismissed the popup, don't show it!
                if (hasDismissedPopup && hasDismissedPopup.length > 0) {

                } else {
                    // Otherwise, show it!
                    popup.classList.toggle('hidden');
                    this.dialog = new A11yDialog(popup);

                    this.form = new Formie();
                    this.form.initForm(newsletterForm);

                    disableBodyScroll(popup);
                }

            } else {
                // If not present, it's the first page load, and we don't want to show the popup yet.
                // Set 'isRepeatVisitor' cookie to true, stop
                this.setCookie('isRepeatVisitor', true, 1);
            }
        }

        // Now, add listeners

        // Close the popup and save that the user has done so on click to the X button
        closeButton.addEventListener('click', (e) => {
            popup.classList.toggle('hidden');
            self.setCookie('hasDismissedPopup', true, 1);
            self.dialog.destroy();
            enableBodyScroll(popup);
        });

        if (newsletterForm) {
            // Indicate that the user is subscribed already upon form submission
            newsletterForm.addEventListener('submit', function(e) {
                self.setCookie('subscribed', true, 1);
            });

            // Just for testing
            newsletterForm.addEventListener('onAfterFormieSubmit', (e) => {
                //console.log(e.detail);
            });
        }
    }
};
export default newsletterPopup;